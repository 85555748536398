<template lang="pug">
  header.header
    .header__container
      router-link.header__logo.logo(:to="{ name: 'Home' }")
        img(
          :src="Logo"
          alt="logo"
        )
      .header__menu.menu
        button.menu__icon.icon-menu(
          type="button"
          :class="{ 'menu-open': isMenuOpen }"
          @click="isMenuOpen = !isMenuOpen; openMenu()"
        )
          span
        nav.menu__body(
          :class="{ 'menu-open': isMenuOpen }"
          @click="isMenuOpen = !isMenuOpen; openMenu()"
        )
          ul.menu__list
            li.menu__item
              router-link.menu__link(:to="{ name: 'Home' }") Головна
            li.menu__item
              router-link.menu__link(:to="{ name: 'Team' }") Наша команда
            li.menu__item
              router-link.menu__link(:to="{ name: 'Timetable' }") Розклад
            li.menu__item
              router-link.menu__link(:to="{ name: 'Gallery' }") Галерея
            li.menu__item
              router-link.menu__link(:to="{ name: 'News' }") Новини
            li.menu__item
              router-link.menu__link(:to="{ name: 'Home', hash: '#contacts' }") Контакти
</template>

<script>
import Logo from '@/assets/images/logo.svg'
export default {
  name: 'HeaderBlock',
  data () {
    return {
      Logo,
      isMenuOpen: false
    }
  },
  methods: {
    openMenu () {
      if (document.documentElement.clientWidth <= 767) {
        document.body.classList.toggle('lock')
      }
    }
  }
}
</script>
